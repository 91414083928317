import React from 'react'
import CustomerOnboardingIcon from '../../../images/customer-onboarding.inline.svg'
import KMLAMLIcon from '../../../images/kml-aml.inline.svg'
import FraudIcon from '../../../images/fraud.inline.svg'
import './index.scss'
import RegularButton from '../../common/button'
//@ts-ignore
import { Element } from 'react-scroll'
import { OpenLetsConnectUrl } from '../../../schemas/header-navigation'

export const UseCasesLadingSection: React.FC = () => {
  return(
	<section className='landing-use-cases-section__cta-container'>
		<h3>Catch a glimpse of how we set a new standard as the identity partner for LatAm</h3>
		<RegularButton text="Let’s Connect" action={OpenLetsConnectUrl}/>
	</section>
	)
}