import React from "react"
import Header from "../header"
import Footer from "../footer"
import './index.scss'

export interface Ilayout {
  children: JSX.Element
}

const Layout = ({ children }: Ilayout) => {
  return (
    <div className="zenpli-main-layout">
      <Header />
      <main className="zenpli-main-layout__content">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout