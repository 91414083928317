import React, { useEffect, useState } from 'react'
import DesktopImage from '../../../images/api.inline.svg';
import MobileImage from '../../../images/api-mobile.inline.svg'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
//@ts-ignore
import { Element } from 'react-scroll'
import './index.scss'
import { isTablet } from 'react-device-detect';

interface CarouselCard {
    title: string,
    description: string,
    disclaimer: string
    cardImage: any,
	transform: string,
}

const getCards: (isTablet: boolean) => CarouselCard[] = (isTablet: boolean) => {
	const CAROUSEL_CARDS: CarouselCard[] = [
		{
			title: 'Identity Decisioning',
			description: 'Single API with all the identity data needed to deliver anti-spoof onboarding decisions',
			cardImage: '../../../images/automated-case-manager.png',
			disclaimer: '>30 risk signals analyzed in less than 10 seconds (and growing)',
			transform: isTablet ? 'translateX(2.5rem)' : 'translateX(6.25rem)'
		},
		{
			title: 'Local Regulation & Identity Data',
			description: 'Enable your Compliance and Fraud Ops to customize risk scoring based on your business needs, or use pre-built use cases',
			cardImage: '../../../images/local-regulation.png',
			disclaimer: 'Low complexity & time-to-market',
			transform: 'translateX(-830px)'
		},
		{
			title: 'Smart Orchestration',
			description: 'Dynamic and interconnected business rules that optimizes 360° decisioning',
			cardImage: '../../../images/smart-orchestration.png',
			disclaimer: 'Determine risk-appetite, not chained happy paths',
			transform: 'translateX(-1755px)'
		},
		{
			title: 'Automated Case Manager',
			description: 'A single view of historical decisions to streamline operations',
			cardImage: '../../../images/automated-case-manager.png',
			disclaimer: 'Audit, reporting, and analytics made easy',
			transform: 'translateX(-2300px)'
		},
	];
	return CAROUSEL_CARDS;
}

export const PlatformLadingSection = (props: any) => {
	const [ slideIndex, setSlideIndex ] = useState<number>(0);
	const [ cards, setCards ] = useState<CarouselCard[]>([])
	//@ts-ignore
	const images = props.images.filter(({ node: { relativePath } }) => relativePath.indexOf('slider-') > -1);

	useEffect(() => {
		setCards(getCards(isTablet))
	}, [])

    return (
        <section className='landing-platform-section'>
			<h1 className='main-container--title landing-platform-section__title'>Your partner across the entire identity journey. Connect to our platform and leave the rest to us.</h1>
			<figure className='no-mobile landing-platform-section__image--large'>
				<DesktopImage />
			</figure>
			<figure className='landing-platform-section__image'>
				<MobileImage />
			</figure>
			<Element name="platform">
				<>
					<h1 className='main-container--title landing-platform-section__apis-title'>
						Manage and operate all of your KYC and Fraud needs with just a few clicks through an state of the art platform built to tackle the identity challenge in LatAm
					</h1>
					<section className='landing-platform-section__carrousel'>
						{cards.map((card, index) => 
							<>
								<div
									className='landing-platform-section__mockup-card no-mobile'
									style={{ transform: cards[slideIndex].transform  }}
									key={`desktop-card-${index}`}
								>
									<aside>
										<span>{card.title}</span>
										<h3>{card.description}</h3>
										<div>
											<small>{card.disclaimer}</small>
										</div>
									</aside>
									<figure>
										<GatsbyImage image={getImage(images[index].node)} alt={card.title} />
									</figure>
								</div>
								<div 
									className='landing-platform-section__mockup-card landing-platform-section__mockup-card--mobile'
									key={`mobile-card-${index}`}
								>
									<aside>
										<span>{card.title}</span>
										<h3>{card.description}</h3>
										<div>
											<small>{card.disclaimer}</small>
										</div>
									</aside>
									<figure>
										<GatsbyImage image={getImage(images[index].node)} alt={card.title} />
									</figure>
								</div>
							</>
						)}
					</section>
					<div className='landing-platform-section__scroller'>
						<span>{'<'} Scroll {'>'}</span>
					</div>
					<section className='landing-platform-section__carrousel-dots no-mobile'>
						{cards.map((_, index) => 
							<figure 
								onClick={() => setSlideIndex(index)}
								key={`dot-${index}`}
								className={`
									landing-platform-section__carrousel-dot 
									${index === slideIndex ? 'landing-platform-section__carrousel-dot--active' : ''}
								`}
							/>
						)}
					</section>
				</>
			</Element>
        </section>
    )
}