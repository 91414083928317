import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/common/layout"
import { PlatformLadingSection } from "../components/landing/platform"
import { UseCasesLadingSection } from "../components/landing/use-cases"
import IllustrationSvg from '../images/Illustration.inline.svg'
//@ts-ignore
import { scroller, Element, animateScroll as scroll } from 'react-scroll'
import '../styles/main.scss'
import '../styles/pages/index/index.scss'


// markup
const IndexPage = (props: any) => {
  const handleAction = () => {}
	React.useEffect(() => {
		const { hash } = window.location
		if (hash === '' ||  hash === '#') {
			scroller.scrollTo(0);
		}

		if (hash === '#what-we-do') {
			scroll.scrollToTop()
		} else {
			scroller.scrollTo(
				window.location.hash.replace('#', ''), 
				{ 
					offset: -150,
					smooth: true,
					duration: 500, 
				}
			)
		}

	}, [])

  return (
    <Layout>
      <>
		<div className="main-container main-container__texts main-container--secondary">
			<IllustrationSvg />
			<div>
				<h1 className="main-container--title">
					All-in-one platform that seamlessly delivers accurate identity decisions
				</h1>
				<p className="main-container--description">
					Zenpli makes it easy to manage KYC and fraud complexity through a single modular API, no matter the use case
				</p>
			</div>
		</div>
		<Element name="what-we-do">
			<section>
				<PlatformLadingSection images={props.data.photos.edges}/>
			</section>
		</Element>
		<section>
			<UseCasesLadingSection />
		</section>
      </>
    </Layout>
  )
}

export const Head = () => (
  <>
    <title>Zenpli</title>
    <meta name="description" content="Zenpli" />
  </>
)

export const pageQuery = graphql`

query images {
	photos: allFile(
		sort: { fields: base, order: ASC }
		filter: { extension: { regex: "/(png)/" } }
	) {
		edges {
			node {
				relativePath
				childImageSharp {
					gatsbyImageData(height: 300, width: 400)
				}
			}
		}
	}
}
`;

export default IndexPage
